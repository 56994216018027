@use 'variables' as *;
.section {
  background-color: $color-primary;
  padding: 2rem;

  @media screen and (max-width: $sm) {
    padding: 0 0 2.5rem 0;
  }
}

.paragraph {
  max-width: 39.25rem;
}

.title {
  max-width: 51.93rem;
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 800;
  text-align: center;

  @media screen and (max-width: $sm) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.searchBtn {
  background-color: white;
  width: 3.4rem;
  height: 3.125rem;
  border-radius: 0.625rem;
}

.input {
  width: 44.21rem;
  height: 3.125rem;
  border-radius: 0.625rem;
  outline-color: $color-primary;
  color: $mine-shaft;
  padding: 10px;

  
  @media screen and (max-width: $md) {
    width: calc(100% - 6rem);
  }

  @media screen and (max-width: $sm) {
    width: 100%;
  }
}

.publicationsContainer {
  width: 48rem;
  padding: 0;

  @media screen and (max-width: $lg) {
    width: 100%;
    padding: 0.5rem 1rem;
  }

  @media screen and (max-width: $sm) {
    padding: 0;
  }
}

.loadingBox {
  padding: 1rem 0 10rem 0;
}

.pageBottom {
  min-height: 25rem;

  @media screen and (max-width: $lg) {
    min-height: auto;
  }
}

.ctaCard {
  right: 1rem;
  top:2rem;
  position: sticky;
  margin-top: 2rem;

  @media screen and (max-width: $lg) {
    top: 0;
    right: 0;
    margin: 2rem;
    position: inherit;
  }

  @media screen and (max-width: $sm) {
    margin: 1rem;
  }
}