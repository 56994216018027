@use 'variables' as *;
.header {
  background-color: $alabaster;
}

.menuLinks {
  a {
    color: $mine-shaft2;
    font-size: 0.9375rem;
  }
}

.menuItem {
  background-color: $alabaster;
}