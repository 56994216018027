@use 'variables' as *;
.ellipsis {
}

.item {
  width: 2rem;
  height: 2rem;
  border: 1px solid $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $default-transition;
  border-radius: 0.5rem;

  &:hover {
    background-color: $silver;
  }
}

.active {
  border: 1px solid $color-secondary;
}
