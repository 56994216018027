@use 'variables' as *;
.button {
  background-color: $gray;
  color: white;
}

.card {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #00000012;
  border: 2px solid $color-primary;
  border-radius: 20px;
  padding: 2rem 1rem;

  @media screen and (min-width: $xxl) {
    max-width: 22.5rem;
    padding: 2rem 1.5rem;
  }

  @media screen and (max-width: $xxl) {
    max-width: 15.625rem;
  }

  @media screen and (max-width: $lg) {
    max-width: 100%;
  }
}

.title {
  text-align: center;
  font: normal normal bold 1rem/2rem $font-primary;
  letter-spacing: 0.03125rem;
  color: $color-primary;

  @media screen and (min-width: $xxl) {
    max-width:  22.5rem;
    font: normal normal bold 1.5625rem/2.1875rem $font-primary;
  }
}

.list {
  li {
    font: normal normal normal 0.5625rem/0.9375rem $font-primary;
    letter-spacing: 0.0225rem;
    color: $color-primary;

    @media screen and (min-width: $md) {
      font: normal normal normal 1.2rem/1.2rem $font-primary;
    }

    @media screen and (min-width: $lg) {
      font: normal normal normal 0.5625rem/0.9375rem $font-primary;
    }
  }
}

.input {
  font-size: 0.9375rem;
  width: 100%;
}