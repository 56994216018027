@use 'variables' as *;
$lineHeight: 1.375rem;

.resume {
  line-height: $lineHeight;
  color: $mine-shaft;
}

.title {
  color: $color-primary;
  font-weight: 800;
  line-height: $lineHeight;
  text-decoration: underline;
}

.year {
  line-height: $lineHeight;
  color: $dove-gray;
  text-align: right;
}
