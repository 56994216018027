@use 'variables' as *;
.leftContainer {
  height: 3.5rem;
  font-size: 0.875rem;

  @media screen and (max-width: $md) {
    height: auto;
  }
}

.divider {
  height: 1.1rem;
  width: 1px;
  background-color: $mine-shaft;
}

.rightContainer {
  width: 20rem;
  margin-right: 5rem;

  @media screen and (max-width: $md) {
    width: auto;
    margin: 0.5rem auto 1rem auto;
  }
}
